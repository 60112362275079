<template>
	<div>
		
	</div>
</template>
<script>
	export default {
		name: 'footerElement',
		data() {
			return {
				//
			}
		},
		methods: {
			
		},
		computed: {
			
		},
		mounted() {
			
		},
		components: {
			
		},
	}
</script>
<style>
</style>