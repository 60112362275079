<template>
    
	<div class="welcome-email-tabs">
		<p>
			{{ $t('WelcomeEmailTabs') }}
		</p>
		<div>
			
			<div class="row">
				<div class="col-6">
					<div class="form-group">
						<label for="sender_email">{{ $t('SenderEmail') }}</label>
						<input type="email" id="sender_email" v-model="confirmationArray.sender_email" @change="$emit('update:confirmationChanged',true)" class="form-control" :placeholder="$t('EmailAddress')" required>
					</div>
				</div>
				<div class="col-6">
					<div class="form-group">
						<label for="sender_name">{{ $t('SenderName') }}</label>
						<input type="text" id="sender_name" v-model="confirmationArray.sender_name" @change="$emit('update:confirmationChanged',true)" class="form-control" :placeholder="$t('SenderName')" required>
					</div>
				</div>
			</div>
			
			<div class="row">
				<div class="col-6">
					<div class="form-group">
						<label for="from_email">{{ $t('Replytoemail') }}</label>
						<input type="email" id="from_email" v-model="confirmationArray.from_email" @change="$emit('update:confirmationChanged',true)" class="form-control" :placeholder="$t('EmailAddressWillReplyTo')" required>
					</div>
				</div>
				<div class="col-6">
					<div class="form-group">
						<label for="from_name">{{ $t('Replytoname') }}</label>
						<input type="text" id="from_name" v-model="confirmationArray.from_name" @change="$emit('update:confirmationChanged',true)" class="form-control" :placeholder="$t('Name')" required>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label for="subject">{{ $t('Subject') }}</label>
						<input type="text" id="subject" v-model="confirmationArray.subject" @change="$emit('update:confirmationChanged',true)" class="form-control" placeholder="Confirmation subject" required>
						<p class="help-block" style="display:none;">asdasdasdasdas</p>
					</div>
				</div>
				<!--div class="col-6">
					<div class="form-group">
						<label for="template">Predefined Template</label>
						<select id="template" class="form-control" v-model="templateId" @change="selectTemplate">
							<option v-for="(template, key) in templates" :value="key" v-bind:key="key">
								{{ template.name }}
							</option>
						</select>
					</div>
				</div-->
			</div>
			<div class="form-group">
				<label for="confirmation_message">
					{{ $t('Message') }}
					
					<i class="fa fa-question-circle text-secondary" id="popover-target-we"></i>
					<b-popover target="popover-target-we" triggers="hover" placement="top">
						<div class="pb-1"><b>{{$t('AvailableTags')}} :</b></div>
						<div>[lead_fullname]</div>
						<div>[lead_firstname]</div>
						<div>[lead_lastname]</div>
						<div>[lead_phone]</div>
						<div>[lead_email]</div>
					</b-popover>
					
				</label>
				<!--vue-editor id="confirmation_message" v-model="confirmationArray.content" :editorToolbar="customToolbar"></vue-editor-->
				<quill-editor v-model="confirmationArray.content"  @change="$emit('update:confirmationChanged',true)" :options="editorOption" :content="confirmationArray.content" />
				
			</div>
		</div>
	</div>
</template>
<script>
    // import {mapActions, mapGetters} from 'vuex'
    // import {VueEditor} from 'vue2-editor'
    // import mapKeys from 'lodash/mapKeys'
    // import {ErrorBag, Validator} from 'vee-validate';
	
	import { quillEditor } from 'vue-quill-editor'
	
    export default {
        name: 'welcome-email',
		props: {
			confirmationArray: Object,
			confirmationChanged:Boolean
		},
        data() {
            return {
                templates: [],
                templateId: '',
                errors: '' /* new ErrorBag() */,
				
                /* customToolbar: [
					["bold", "italic", "underline"],
					[{ header: [6, 5, 4, 3, 2, 1, false] }],
					[{ 'align': [] }],
					[ { list: "ordered" }, { list: "bullet" }], 
					[{ indent: "-1" }, { indent: "+1" }],
					[ { color: ['#000000', '#663d00', '#f5523c', '#377dff', '#ccc', '#ffc107', '#28a745'] }, { background: [] }],
					[{ 'font': [] }],
				], */
				
				editorOption: {
					placeholder: this.$t('InsertYourMessage'),
					modules: {
						toolbar : {
							container: [
								
								["bold", "italic", "underline"],
								[{ header: [6, 5, 4, 3, 2, 1, false] }],
								[{ 'align': [] }],
								[ { list: "ordered" }, { list: "bullet" }], 
								[{ indent: "-1" }, { indent: "+1" }],
								[ { color: ['#000000', '#663d00', '#f5523c', '#377dff', '#ccc', '#ffc107', '#28a745'] }, { background: [] }],
								['link'],
								[{ 'font': [] }],
								
							],
						},
					},
				},
				
                loading: true
            }
        },

        components: {
            // InputToggle,
            // Icon,
            // VueEditor,
			quillEditor
        },
        mounted() {
            
            this.loadTemplates();
			/* 
            //Fetch confirmation templates
            this.adjustLeadInfoEditorDropdown();
			*/
        },
        methods: {
            selectTemplate() {
				let that = this
				if( typeof that.templates[that.templateId] !== "undefined" ) {
					that.confirmationArray.content = that.templates[that.templateId].body;
				}
			},
            loadTemplates() {
                
				let that = this
				let url   = 'get_templates'
				let method= 'GET'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					let dataModel = response.data.templates
					dataModel.forEach( function(model) {
						that.templates.push(model)
					} );
					// that.templates = response.data;
					// that.loading	  = false;
					that.selectTemplate();
					
				}).catch(( {response} ) => {
					console.log(response)
				})
				
            },
            adjustLeadInfoEditorDropdown() {
                // We need to manually supply the HTML content of our custom dropdown list
                const placeholderPickerItems = Array.prototype.slice.call(
                    document.querySelectorAll('.ql-placeholder .ql-picker-item')
                );
                placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);

                document.querySelector('.ql-placeholder .ql-picker-label').innerHTML = 'Lead infos' + document.querySelector('.ql-placeholder .ql-picker-label').innerHTML;
            },
        },
        computed: {
            /* ...mapGetters({
                templates: 'sources/getTemplates',
            }), */
        },
    }
</script>

<style scoped lang="scss">
	.welcome-email-tabs {
		.modal-header {
			justify-content: flex-start;
		}
		.modal-title {
			font-size: 1.3em;
		}
		select, input {
			min-height: 40px;
		}
		.disable-modification {
			pointer-events: none;
			opacity: 0.5;
		}
		.spinner {
			margin-bottom: -5px;
			margin-left: 5px;
		}
	}
</style>