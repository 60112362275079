<template>
    <highcharts :options="chartOptions" id="chart_status"></highcharts>
</template>

<script>
    import {Chart} from 'highcharts-vue'

    export default {
        name: "status-chart",
		props: {
			data: {
                required: true,
				// type: Array,
            },
        },
        data() {
            return {
                chartOptions: {
					colors: ['#48a6e8', '#0d4770', '#57a869', '#001a41'], // // this.colors
					chart: { plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false, type: 'pie' },
                    title: { text: null },
                    tooltip: { headerFormat: '', pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' + 'Leads: <b>{point.y}</b><br/>' },
                    plotOptions: {
                        pie: { allowPointSelect: true, cursor: 'pointer', dataLabels: { enabled: false }, showInLegend: true }
                    },
                    series: [{
                        dataLabels: {
                            enabled: false
                        },
                        minPointSize: 0,
                        showInLegend: true,
                        innerSize: '50%',
                        zMin: 0,
                        name: 'status',
                        data: this.data
                    }]
                }
            }
        },
        components: {
            highcharts: Chart
        }
    }
</script>

<style scoped>
    #chart_status {
        min-height: 507px; 
    }
</style>
