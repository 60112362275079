<template>
    <highcharts :options="options" id="chart_status"></highcharts>
</template>

<script>
    import {Chart} from 'highcharts-vue'

    export default {
        name: "source-chart",
        props: {
            data: {
                required: true
            }
        },
        data() {
            return {
                options: {
                    colors: ['#0ccef1', '#001a41', '#4267b1', '#48a6e8'],
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    },
                    series: [{
                        type: 'pie',
                        allowPointSelect: true,
                        data: this.data,
                        showInLegend: false
                    }]
                }
            }
        },
        components: {
            highcharts: Chart
        }
    }
</script>

<style scoped>

</style>