<template>
    <nav id="nav" class="menuGlob">
        <ul class="ul-menu-md">
            <!--UNLOGGED-->
            <template v-if="!$auth.check()">
				<li v-for="(route, key) in routes.unlogged" v-bind:key="route.path">
					<router-link  :to="{ name : route.path }" :key="key" class="pl-5 pr-5">
						{{route.name}}
					</router-link>
				</li>
			</template>
            <!--LOGGED USER-->
			<template v-if="$auth.check(1)">
				<li v-for="(route, key) in routes.user" v-bind:key="route.path">
					<router-link  :to="{ name : route.path }" :key="key" class="pl-5 pr-5">
						{{route.name}}
					</router-link>
				</li>
			</template>
            <!--LOGGED ADMIN-->
			<template v-if="$auth.check(2)">
				<li v-for="(route, key) in routes.admin" v-bind:key="route.path">
					<router-link  :to="{ name : route.path }" :key="key" class="pl-5 pr-5">
						{{route.name}}
					</router-link>
				</li>
			</template>
            <!--LOGOUT-->
            <li v-if="$auth.check()">
                <a href="#" @click.prevent="$auth.logout()">{{$t('Logout')}}</a>
            </li>
			
            <li class="float-right">
				<div>
					<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret class="dropdown-md">
						<template v-slot:button-content>
							<span><flag :iso="userLanguage.flag" v-bind:squared=false /> {{ userLanguage.title }}</span>
						</template>
						<!--b-dropdown-item href="#">Action</b-dropdown-item>
						<b-dropdown-item href="#">Another action</b-dropdown-item>
						<b-dropdown-item href="#">Something else here...</b-dropdown-item-->
						
						<b-dropdown-item href="#" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry)">
							<flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
						</b-dropdown-item>
						
					</b-dropdown>
				</div>
            </li>
        </ul>
    </nav>
</template>
<script>
	import i18n from '@/config/i18n';
	export default {
		data() {
			return {
				//
				userLanguage: {
					title	: 'English',
					flag	: 'us',
				},
				languages: [{
					flag: 'us',
					language: 'en',
					title: 'English'
				}, {
					flag: 'es',
					language: 'es',
					title: 'Español'
				}],
				routes: {
					// UNLOGGED
					unlogged: [
						{
							name: this.$t('Register'),
							path: 'register'
						},
						{
							name: this.$t('Login'),
							path: 'login'
						}/* ,
						{
							name: 'Pricing',
							path: 'pricing'
						} */
					],
					// LOGGED USER
					user: [
						{
							name: this.$t('Dashboard'),
							path: 'dashboard'
						}
					],
					// LOGGED ADMIN
					admin: [
						{
							name: this.$t('Dashboard'),
							path: 'admin.dashboard'
						}
					]
				}
			}
		},
		methods: {
			changeLocale(entry) {
				let that = this
				i18n.locale = entry.language;
				that.userLanguage.title = entry.title
				that.userLanguage.flag	= entry.flag
			}
		},
		mounted() {
			//
		}
	}
</script>

<style lang="scss">
	.menuGlob {
		width: 100%;
		.ul-menu-md {
			margin: 30px 50px;
			>li {
				display: inline-block;
				a {
					color: #4c4c4c;
				}
			}
		}
		
		.dropdown-md {
			.dropdown-item:hover {
				color: #ffffff;
			}
			.dropdown-toggle {
				padding: 10px 20px;
			}
		}
		
	}
</style>