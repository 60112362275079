<template>
	<div>
		<p>{{ $t('MatchTitle') }}</p>
		<form id="mapping__form" class="form-horizontal"><!-- @keydown.enter="save"  -->
			<div class="form-group row" v-for="(fb_field, key) in questionsFields" v-bind:key="`fb-${key}`">
				<label :for="'fb-' + fb_field.id" class="col-4 control-label lead-source-mapping__label">{{ fb_field.label }}</label>
				<div class="col-1 lead-source-mapping__separator-wraper my-auto">
					<i class="fa fa-long-arrow-right"></i>
				</div>
				<div :class="[ fields[fb_field.key] == 'custom_field' ? 'col-3' : 'col-7']">
					<select @change="updateFieldValue(fb_field, $event.target.value)" v-model="fields[fb_field.key]" :name="'fields.' + fb_field.key" :id="'fb-' + fb_field.id" class="form-control" required :disabled="DisableFields">
						<option value="">{{ $t('SelectAField') }}</option>
						<option v-for="(oml_field, key) in mapping_fields" :value="oml_field.key" :disabled="oml_field.key != 'custom_field' ? oml_field.selected : false" v-bind:key="`fld-${key}`"> {{ oml_field.label }} </option>
					</select>
				</div>
				<div v-if="fields[fb_field.key] == 'custom_field'" class="col-4">
					<input type="text" class="form-control" v-model="fieldsLabel[fb_field.key]" :disabled="DisableFields" />
				</div>
			</div>
			
			<!--div class="form-group row" v-for="(fb_field, key) in fb_field_static" v-bind:key="`st-${key}`">
				<label :for="'fb-' + fb_field.key" class="col-4 control-label lead-source-mapping__label">
					{{ fb_field.label }}
				</label>
				<div class="col-1 lead-source-mapping__separator-wraper my-auto">
					<i class="fa fa-long-arrow-right"></i>
				</div>
				<div :class="[ fields[fb_field.key] == 'custom_field' ? 'col-3' : 'col-7']">
					<select @change="updateFieldValue(fb_field, $event.target.value)" v-model="fields[fb_field.key]" :name="'fields.' + fb_field.key" :id="'fb-' + fb_field.key" class="form-control" required>
						<option value="">Select a Field</option>
						<option v-for="(oml_field, key) in mapping_fields" 	:value="oml_field.key" 	:disabled="oml_field.key != 'custom_field' ? oml_field.selected : false" v-bind:key="`map-${key}`"> {{ oml_field.label}} </option>
					</select>
				</div>
				<div v-if="fields[fb_field.key] == 'custom_field'" class="col-4">
					<input type="text" class="form-control" v-model="fieldsLabel[fb_field.key]" />
				</div>
			</div-->
		</form>
	</div>
</template>

<script>
    export default {
		name: 'fields-mapping',
		props: {
			questionsFields: Array,
			fields		   : Object,
			fieldsLabel	   : Object,
			mapping_fields : Array,
			DisableFields  : { Type: Boolean, default: false }
		},
        data() {
            return {
                fb_field_static: [
                    { label: 'Campaign Name', 	key: 'campaign_name' 	},
					{ label: 'Ad Name', 		key: 'ad_name' 			},
                    { label: 'Adset Name', 		key: 'adset_name' 		}
                ],
            }
        },

        components: {
            
        },
        mounted() {
            
        },
        methods: {
            updateFieldValue(element, val) {
				this.$emit('updateFieldValue', element.key, val, element.label)
			}
        },
        computed: {
			
        },
    }
</script>
