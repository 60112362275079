<template>
    <vue-funnel-graph :width="width" :height="height" :labels="labels"
            :values="values" :colors="colors" :direction="direction"
            :gradient-direction="gradientDirection"
            :animated="true" :display-percentage="true">
    </vue-funnel-graph>
</template>

<script>
    import { VueFunnelGraph } from 'vue-funnel-graph-js';

    export default {
        name: "funnel-chart",
        props: {
            data: {
                required: true
            }
        },
        data() {
            return {
                labels: [this.$t('Leads'), this.$t('Processed'), this.$t('Qualified')],
				// subLabels: ['Direct'],
                values: [
                // with the given Labels and SubLabels here's what the values represent:
                //
                // Direct, Social, Ads
                //    |      |     |
                //    v      v     v
                    this.data[0].values[0], // Segments of "Impressions" from top to bottom
                    this.data[1].values[0], // Segments of "Add To Cart"
                    this.data[2].values[0]   // Segments of "Buy"
                ],
                colors: [
                    this.data[0].backgroundColor,
                    this.data[1].backgroundColor,
                    this.data[2].backgroundColor
                ],
                direction: 'vertical',
                gradientDirection: 'vertical',
                height: 500,
                width: 380
            };
        },
        components: {
            VueFunnelGraph
        }
    }
</script>

<style scope>
  .label__value {
    color: #2c3348 !important;
  }
  .svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label:not(:first-child) {
    border-top: 1px solid #d8dde5;
  }
  .svg-funnel-js svg {
    margin: 0 auto;
  }
  .svg-funnel-js.svg-funnel-js--vertical {
    width: 100%;
  }
  .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
    color: #377dff;
  }
  .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
    color: #9E9E9E;
  }
  .graph-title{
    font-size: 1.2rem;
    margin-bottom: 3rem;
    margin-top: 0;
    color: #2c3348;
  }
</style>
