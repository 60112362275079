<template>
	<div :class="[ 'DropOptions', maxWidth ? 'w-100' : '' ]" ref="dropOptions">
		<button v-on:click="ToggleDropdown()" :class="['label-btn', maxWidth ? 'w-100' : '']">
			<slot name="label">
				<div>
					<slot name="title">
						<span v-if="faIcon != ''"><i :class="['fa faIcon', faIcon]"></i></span>
						<span v-html="SelectedValue"></span>
					</slot>
					<i :class="[dropdown ? 'fa fa-caret-up' : 'fa fa-caret-down', 'caret']"></i>
				</div>
			</slot>
		</button>
		<transition name="slide-fade">
			<div :class="['drop-options', right ? 'right' : '']" v-if="dropdown">
				<div class="input-group border-bottom">
					<input class="form-control border-0" :placeholder="$t('Search2')" v-model.trim="FilterText" />
					<div class="input-group-append">
						<span class="input-group-text border-0 bg-white"><i class="fa fa-search"></i></span>
					</div>
				</div>
				<ul class="ul-options m-0">
					<slot name="options" v-bind:options="FilteredOptions">
						<li v-for="(item, id) in FilteredOptions" v-bind:key="`li-${id}`" :class="[SelectedId == id ? 'active' : '']" @click="onChange(item, id)">
							<div>{{ item.title }}</div>
						</li>
						<li v-if="FilteredOptions.length == 0" class="text-center">{{$t('NoItems')}}</li>
					</slot>
				</ul>
			</div>
		</transition>
	</div>
</template>
<script>
	export default {
		name: 'DropOptions',
		components: {
			
		},
		props: {
			label   : { type: String, default: '' },
			options : { type: Array },
			right   : { type: Boolean, default: false },
			value   : { },
			maxWidth: { type: Boolean, default: false },
			faIcon	: { type: String, default: '' }
		},
		data() {
			return {
				dropdown	 : false,
				SelectedValue: 'dropdown options',
				FilterText	 : '',
				SelectedId	 : 'NaN'
			}
		},
		watch: {
			value(val) {
				let that	  = this
				that.dropdown = false
				that.options.forEach( function(option, id) {
					if( val == option.id ) {
						that.SelectedId = id
						that.SelectedValue = option.title
					}
				} )
			}
		},
		mounted () {
			let that = this
			that.SelectedValue = that.label
			
			if( that.value != '' ) {
				this.options.forEach( function(option, id) {
					if( that.value == option.id ) {
						that.SelectedId = id
						that.SelectedValue = option.title
					}
				} )
			} else {
				that.SelectedValue = that.label
			}
		},
		methods: {
			onChange(element, id) {
				let that = this
				that.dropdown 		= false
				that.FilterText 	= ''
				that.SelectedValue 	= element.label
				that.SelectedId		= id
				that.$emit('input', element.option)
			},
			ToggleDropdown() {
				document.addEventListener('click', this.documentClick);
				this.dropdown = !this.dropdown
				// this.dropdown = this.dropdown === true ? false : true
			},
			documentClick(e) {
				let that = this, el = this.$refs.dropOptions, target = e.target;
				if( typeof el != 'undefined' && el != null && el != '' ) {
					if(el !== target && !el.contains(target)) {
						that.dropdown = false
						document.removeEventListener('click', that.documentClick);
					}
				}
			}
		},
		computed : {
			FilteredOptions() {
				var visibleOptions = [],
				FilterText = this.FilterText.trim().length > 0 ? this.FilterText.toLowerCase() : null;
				
				if (FilterText) {
					visibleOptions = this.options.filter((item) => {
						return FilterText.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v));
					});
				} else {
					visibleOptions = this.options;
				}
				
				return visibleOptions.map(item => {
					let label = '', title = item.title, option = item.id;
					if (FilterText) {
						
						if( FilterText.indexOf(' ') >= 0 ) {
							let arChar = FilterText.split(' ')
							label = title;
							arChar.forEach( function( str ) {
								label = label.replace(str, str);
							} )
						} else {
							let searchStartIndex = title.toLowerCase().indexOf(FilterText), 
							filterPart = title.substring(searchStartIndex, searchStartIndex + FilterText.length);
							label = title.replace(filterPart, filterPart);
						}
						
					} else {
						label = title;
					}
					return {
						option 	: option,
						title	: label,
						label	: title
					};
				});
			}
		},
	}
</script>
<style lang="scss">
	
	.DropOptions {
		
		display: inline-block;
		position: relative;
		
		.label-btn {
			min-width: 14em;
			height: 40px;
			border-radius: 4px;
			outline-style: none;
			text-align: left;
			padding-left: 15px;
			padding-right: 15px;
			background-color: #fff;
			border: 1px solid #ced4da;
			font-size: 13px;
			
			.caret {
				position: absolute;
				right: 15px;
				top: 15px;
			}
			
			.faIcon {
				width: 22px;
			}
		}
		
		.drop-options {
			input {
				font-size: 13px;
			}
			.fa-search {
				color: #ddd;
				font-size: 14px;
			}
			.ul-options {
				list-style: none;
				padding: 0;
				max-height: 15em;
				overflow-y: auto;
				li {
					padding: 8px 10px;
					cursor: pointer;
					color: #676d73;
					&:hover {
						background: #eee;
					}
				}
				li.active {
					background-color: #eee !important;
				}
			}
			
			background: #fff;
			
			position: absolute;
			
			z-index: 9;
			float: left;
			min-width: 10rem;
			width: 100%;
			padding: 0px;
			margin-top: 2px;
			font-size: 0.875rem;
			color: #212529;
			text-align: left;
			list-style: none;
			
			background-clip: padding-box;
			border: 1px solid #ced4da;
			border-radius: 0px;
			
			&.right {
				right: 0;
			}
			
		}
	}
	
	.fade-enter-active, .fade-leave-active {
		transition: opacity .8s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>
